<template>
    <div>
        <div class="d-flex align-center mb-2">
            <v-text-field
                label="Заголовок поля ввода" 
                v-model="settings.label"
                hint="Не обязательно"
                persistent-hint
                class="mr-4"
            ></v-text-field>

            <v-text-field
                label="Доп. текст справа от поля" 
                v-model="settings.prependItem"
                hint="Например, единица измерения"
                persistent-hint
                clearable
                class="maxW-250"
            ></v-text-field>
        </div>

        <div class="d-flex flex-row">
            <v-text-field 
                label="Заполнитель поля"
                v-model="settings.placeholder"
                hint="Текст, который исчезнет после ввода значения" 
                persistent-hint
                type="text"
                class="mr-4"
            ></v-text-field>

            <v-text-field 
                label="Макс. кол-во сим."
                v-model="settings.maxLength"
                hint="Если не указана, длина ввода не ограничена" 
                persistent-hint
                type="number"
                class="mr-4"
            ></v-text-field>

            <v-text-field 
                label="Ширина поля"
                v-model="settings.width"
                type="text"
            ></v-text-field>
        </div>

        <div class="d-flex flex-row">

            <v-checkbox
                v-model="settings.showLabelOnPrint"
                label="Отображать заголовок на печати"
                class="ml-4"
            ></v-checkbox>

            <v-checkbox
                v-model="settings.isDigital"
                label="Цифровое поле"
                :disabled="settings.comparisonType === 'range'"
                class="ml-4"
            ></v-checkbox>

            <template v-if="!settings.isDigital">
                <v-checkbox
                    v-model="settings.considerCase"
                    label="Учитывать регистр"
                    class="ml-4"
                ></v-checkbox>
                <v-checkbox
                    v-model="settings.considerWhiteSpace"
                    label="Учитывать пробелы"
                    hint="Убираем пробелы в начале и конце, пробелы в словах схлопываем до одного"
                    persistent-hint
                    class="ml-4"
                ></v-checkbox>
            </template>
            <template v-else>
                <v-checkbox
                    v-model="settings.considerMinus"
                    label="Учитывать минус"
                    class="ml-4"
                ></v-checkbox>

                <v-checkbox
                    v-model="settings.considerDot"
                    label="Учитывать точку"
                    hint="Включить, когда точка и запятая имеют разные значения"
                    persistent-hint
                    class="ml-4"
                ></v-checkbox>
            </template>
        </div>

        <v-select
            label="Тип проверки ввода"
            v-model="settings.comparisonType"
            :items="availableComparisonTypes"
            @change="onComparisonTypeChange"
        ></v-select>
        
        <div>
            <v-card>
                <v-card-text>
                    <div class="d-flex align-center">
                        <span class="text-h6 text--primary mr-5">{{ correctAnswer.length > 1 ? 'Варианты ответа' : 'Ответ' }}</span>
                        <instruction-btn v-if="settings.comparisonType === 'formula'" />
                    </div>
                    <v-text-field
                        v-for="(answer, i) in correctAnswer"
                        :key="i"
                        v-model="correctAnswer[i]"
                        :type="settings.isDigital ? 'number' : 'text'"
                        :hint="answerHint"
                        persistent-hint
                    >
                        <template v-slot:append-outer>
                            <v-icon v-if="settings.comparisonType === 'one_from_array' && correctAnswer.length > 1" @click="answerRemove(i)">mdi-minus-circle-outline</v-icon>
                        </template>
                    </v-text-field>
                    <v-icon
                        v-if="settings.comparisonType === 'one_from_array'"
                        @click="answerAdd"
                    >mdi-plus-circle-outline</v-icon>
                </v-card-text>
            </v-card>
        </div>
    </div>
</template>

<script>
import interactiveMixin from '@/mixins/interactiveMixin.vue'
import InstructionBtn from './InstructionBtn.vue'

export default {
    components: { InstructionBtn },
    mixins: [interactiveMixin],
    data () {
        return {
            availableComparisonTypes: [
                { text: 'Точное совпадение', value: 'direct' },
                { text: 'Совпадение с одним из вариантов', value: 'one_from_array' },
                { text: 'Наличие всех символов ответов, в любом порядке', value: 'symbols_in_any_order' },
                { text: 'Диапазон числовых значений', value: 'range' },
                { text: 'Формула', value: 'formula' },
            ],
            correctAnswer: [],
            settings: {
                label: '',
                showLabelOnPrint: false,
                maxLength: null,
                width: '100%',
                placeholder: '',
                comparisonType: null,
                considerCase: false,
                considerWhiteSpace: false,
                considerDot: false,
                considerMinus: false,
                isDigital: false,
                prependItem: ''
            }
        }
    },
    computed: {
        answerHint () {
            if (this.settings.comparisonType !== 'formula' || !this.correctAnswer?.[0]?.includes('x')) { return '' }

            try {
                const userFunc = new Function('x', `return ${this.correctAnswer[0]}`)
                // Search for answers
                const answers = []
                let num = 0
                while (answers.length < 5) {
                    if (userFunc(num)) { answers.push(num) }
                    if (num !== 0 && userFunc(-num)) { answers.push(-num) }
                    // Protection from extra long cycles
                    if (num > 10000) { return 'Варианты возможных ответов выходят за рамки автоматического поиска. В выражении возможна ошибка.' }
                    num++
                }
                return `Возможные ответы на это выражение: ${answers.join(', ')}`
            } catch (e) {
                return '...'
            }
        }
    },
    mounted () {
        // If comparisonType not set
        if (!this.settings.comparisonType) {
            this.settings.comparisonType = this.correctAnswer.length > 1 ? 'symbols_in_any_order' : 'direct'
        }
        if (this.correctAnswer.length < 1) {
            this.correctAnswer.push('')
        }
    },
    methods: {
        onComparisonTypeChange () {
            if (this.settings.comparisonType === 'range') {
                this.settings.isDigital = true
                this.correctAnswer = ['', '']
            } else {
                this.correctAnswer = ['']
            }
        },
        answerAdd () {
            this.correctAnswer.push('')
        },
        answerRemove (index) {
            this.correctAnswer.splice(index, 1)
        }
    }
}
</script>