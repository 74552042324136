<template>
  <div>
        <v-label>Настройки холста</v-label>
        <div class="d-flex">
            <v-text-field
                v-model="settings.pointsOffset"
                type="number"
                label="Растояние между точками (px)"
                class="maxW-150 mr-5"
                @input="onMeasuresSettingsChange"
            />

            <v-text-field
                v-model="settings.pointsPerWidth"
                type="number"
                label="Точек по горизонтали"
                class="maxW-100 mr-5"
                @input="onMeasuresSettingsChange"
            />

            <v-text-field
                v-model="settings.pointsPerHeight"
                type="number"
                label="Точек по вертикали"
                class="maxW-100 mr-5"
                @input="onMeasuresSettingsChange"
            />

            <v-text-field
                v-model="settings.labelFontSize"
                type="number"
                label="Размер шрифта лейбла (px)"
                class="maxW-100 mr-5"
                hint="Изменение этого параметра в разметке не отображается"
                persistent-hint
            />
            <v-select
                v-model="settings.mode"
                :items="modeList"
                label="Режим поведения"
            />
        </div>
        
        <v-dialog
            v-model="dialog"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    v-bind="attrs"
                    v-on="on"
                >Разметить задание</v-btn>
            </template>
            <selector
                :key="`${settings.mode}_${settings.pointsOffset}_${settings.pointsPerWidth}_${settings.pointsPerHeight}`"
                v-model="dialog"
                :lines.sync="correctAnswer"
                :presets.sync="settings.preset"
                :points-offset="parseInt(settings.pointsOffset)"
                :points-per-width="parseInt(settings.pointsPerWidth)"
                :points-per-height="parseInt(settings.pointsPerHeight)"
                :mode="settings.mode"
            />
        </v-dialog>
  </div>
</template>

<script>
import interactiveMixin from '@/mixins/interactiveMixin.vue'
import Selector from './Selector.vue'
import { modeList, modeListLabels } from './dictionaries'


export default {
    mixins: [interactiveMixin],
    components: { Selector },
    data () {
        return {
            correctAnswer: [],
            dialog: false,
            settings: {
                pointsOffset: 20, // px
                pointsPerWidth: 10,
                pointsPerHeight: 10,
                labelFontSize: 25, // px
                preset: {},
                mode: null // null | 'independentSections'
            }
        }
    },
    computed: {
        modeList () {
            return Object.keys(modeList).map(key => {
                return { value: modeList[key], text: modeListLabels[modeList[key]] }
            })
        }
    },
    methods: {
        beforeUpdate () {
            ['pointsOffset', 'pointsPerWidth', 'pointsPerHeight'].forEach((key) => {
                this.settings[key] = parseInt(this.settings[key])
            })
            return {
                correctAnswer: this.correctAnswer,
                settings: this.settings
            }
        },
        onMeasuresSettingsChange () {
            // Обнуляем пресет и ответы, потому настройки позиций придется пересчитывать
            this.settings.preset = {}
            this.correctAnswer = []
        }
    }
}
</script>