<template>
    <div>
        <v-label v-if="label">{{ label }}</v-label>
        <editor
            :value="value"
            :init="config"
            :api-key="apiKey"
            @input="updateHandler"
            @paste="updateHandler"
        />
    </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue'
import contentStyle from './plugins/styles/contentStyle.js'
import helpShortcodesTab from './plugins/help/shortcodesInstructions.js'

const shortcodes = {
    textInputShortcode: { init: require('@/components/inputs/wysiwyg-editor/plugins/textInputShortcode.js')?.default, location: 'menu' },
    textInputMultilineShortcode: { init: require('@/components/inputs/wysiwyg-editor/plugins/textInputMultilineShortcode.js')?.default, location: 'menu' },
    formulaInputShortcode: { init: require('@/components/inputs/wysiwyg-editor/plugins/formulaInputShortcode.js')?.default, location: 'menu' },
    familyTreeShortcode: { init: require('@/components/inputs/wysiwyg-editor/plugins/familyTreeShortcode.js')?.default, location: 'menu' },
    clickAndSignShortcode: { init: require('@/components/inputs/wysiwyg-editor/plugins/clickAndSignShortcode.js')?.default, location: 'menu' },
    dropdownShortcode: { init: require('@/components/inputs/wysiwyg-editor/plugins/dropdownShortcode.js')?.default, location: 'menu' },
    textInputFractionShortcode: { init: require('@/components/inputs/wysiwyg-editor/plugins/textInputFractionShortcode.js')?.default, location: 'menu' },
    selectCellsShortcode: { init: require('@/components/inputs/wysiwyg-editor/plugins/selectCellsShortcode.js')?.default, location: 'menu' },
    drawOnGridShortcode: { init: require('@/components/inputs/wysiwyg-editor/plugins/drawOnGridShortcode.js')?.default, location: 'menu' },
    drawPolylineShortcode: { init: require('@/components/inputs/wysiwyg-editor/plugins/drawPolylineShortcode.js')?.default, location: 'menu' },
    conditionalDisplayShortcode: { init: require('@/components/inputs/wysiwyg-editor/plugins/conditionalDisplayShortcode.js')?.default, location: 'menu' },
    accentInWordShortcode: { init: require('@/components/inputs/wysiwyg-editor/plugins/accentInWordShortcode.js')?.default, location: 'menu' },
    audioRecordShortcode: { init: require('@/components/inputs/wysiwyg-editor/plugins/audioRecordShortcode.js')?.default, location: 'menu' },
    audioDictationShortcode: { init: require('@/components/inputs/wysiwyg-editor/plugins/audioDictationShortcode.js')?.default, location: 'menu' },
    parsingWordShortcode: { init: require('@/components/inputs/wysiwyg-editor/plugins/parsingWordShortcode.js')?.default, location: 'menu' },
    parsingSentenceShortcode: { init: require('@/components/inputs/wysiwyg-editor/plugins/parsingSentenceShortcode.js')?.default, location: 'menu' },
    calendarShortcode: { init: require('@/components/inputs/wysiwyg-editor/plugins/calendarShortcode.js')?.default, location: 'menu' },
    divideFigureShortcode: { init: require('@/components/inputs/wysiwyg-editor/plugins/divideFigureShortcode.js')?.default, location: 'menu' },
    mirroredFigureShortcode: { init: require('@/components/inputs/wysiwyg-editor/plugins/mirroredFigureShortcode.js')?.default, location: 'menu' },
    freeDrawingShortcode: { init: require('@/components/inputs/wysiwyg-editor/plugins/freeDrawingShortcode.js')?.default, location: 'menu' },
    dragNDropShortcode: { init: require('@/components/inputs/wysiwyg-editor/plugins/dragNDropShortcode.js')?.default, location: 'menu' },
    truthTableShortcode: { init: require('@/components/inputs/wysiwyg-editor/plugins/truthTableShortcode.js')?.default, location: 'menu' },
    audioStepperShortcode: { init: require('@/components/inputs/wysiwyg-editor/plugins/audioStepperShortcode.js')?.default, location: 'menu' },
    recordStepperShortcode: { init: require('@/components/inputs/wysiwyg-editor/plugins/recordStepperShortcode.js')?.default, location: 'menu' },
    attachFileBtnShortcode: { init: require('@/components/inputs/wysiwyg-editor/plugins/attachFileBtnShortcode.js')?.default, location: 'menu' },
    insertLettersShortcode: { init: require('@/components/inputs/wysiwyg-editor/plugins/insertLettersShortcode.js')?.default, location: 'menu' }
}
let vm = null

export default {
    props: {
        value: { type: String, default: null },
        label: { type: String, default: null },
        useShortcodes: { type: Boolean, default: false },
        showSource: { type: Boolean, default: false }
    },
    components: { Editor },
    data () {
        const extraPlugins = this.useShortcodes ? shortcodes : []  // Include all imported custom shortcodes
        
        if (this.showSource)
            extraPlugins.code = true

        return {
            config: {
                language: 'ru',
                height: '60vh',
                browser_spellcheck: true,
                content_style: contentStyle,
                plugins: ['lists', 'table', 'paste', 'noneditable', 'help', 'image', 'link']
                            .concat(Object.keys(extraPlugins)),
                // table_toolbar: 'tableprops tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',
                toolbar: [
                    {
                        name: 'history', items: [ 'undo', 'redo' ]
                    },
                    {
                        name: 'styles', items: [ 'styleselect' ]
                    },
                    {
                        name: 'formatting', items: [ 'bold', 'italic', 'underline' ]
                    },
                    {
                        name: 'alignment', items: [ 'alignleft', 'aligncenter', 'alignright', 'alignjustify' ]
                    },
                    {
                        name: 'lists', items: [ 'numlist', 'bullist' ]
                    },
                    {
                        name: 'indentation', items: [ 'outdent', 'indent' ]
                    },
                    {
                        name: 'other', items: [ 'image', 'code', 'link' ]
                    }
                ],
                formats: {},
                style_formats: [
                    {
                        title: 'Доп. стили',
                        items: [
                            { title: 'DIV: flex-row, justify-content: center', selector: 'div', styles: { "display": 'flex', "flex-direction": "row", "align-items": "center", "justify-content": "center"  } },
                            { title: 'DIV: flex-row, justify-content: space-between', selector: 'div', styles: { "display": 'flex', "flex-direction": "row", "align-items": "center", "justify-content": "space-between"  } },
                            { title: 'DIV: flex-row, justify-content: space-around', selector: 'div', styles: { "display": 'flex', "flex-direction": "row", "align-items": "center", "justify-content": "space-around"  } },
                            { title: 'DIV: overflow-x: auto', selector: 'div', styles: { "overflow-x": 'auto' } }
                        ]
                    }
                ],
                // The following option is used to append style formats rather than overwrite the default style formats.
                style_formats_merge: true,
                menubar: 'file edit insert view format table shortcodes help',
                menu: {
                    shortcodes: {
                        title: 'Шорткоды',
                        items: Object.keys(shortcodes).filter(key => shortcodes[key].location === 'menu').join(' ') // Adding to menu relative custom shortcodes
                    }
                },
                paste_as_text: true,
                help_tabs: [
                    'shortcuts', // the default shortcuts tab
                    'keyboardnav', // the default keyboard navigation tab
                    'plugins', // the default plugins tab
                    {
                        name: 'shortcodes_tab', // new tab called custom1
                        title: 'Шорткоды',
                        items: [
                            {
                            type: 'htmlpanel',
                            html: helpShortcodesTab,
                            }
                        ]
                    }
                ],
                setup (editor) {
                    // Shortcodes setup
                    editor.getMaxIdValue = vm.getMaxIdValue
                    // Register custom plugins
                    Object.keys(shortcodes).map((key) => {
                        window.tinymce.PluginManager.add(key, shortcodes[key].init)
                    })

                    editor.on('init', () => {
                        // Wait for ad banner and remove it
                        const interval = setInterval(() => {
                            const button = document.querySelector('.tox-notification__dismiss');
                            if (button) {
                                button.click();
                                clearInterval(interval);
                            }
                        })
                    })
                }
            },
        }
    },
    computed: {
        apiKey () {
            return process?.env?.VUE_APP_TINYMCE_API_KEY
        }
    },
    created () {
        vm = this
    },
    methods: {
        updateHandler (content) {
            this.$emit('input', content);
        },
        getMaxIdValue (htmlString) {
            if (!htmlString) { return 0 }

            const regex = /@@shortcode-.*?\sid="(\d*)".*?@@/gm
            let match
            let maxInt = 0
            while ((match = regex.exec(htmlString)) !== null) {
                // This is necessary to avoid infinite loops with zero-width matches
                if (match.index === regex.lastIndex) { regex.lastIndex++ }
                const id = parseInt(match[1])
                if (id > maxInt) { maxInt = id }
            }
            return maxInt
        }
    }
}
</script>