<template>
    <div>
        <div v-if="!hideHeader" class="d-flex justify-space-between align-center mb-5">
            <h2>Подзадание {{ subtask.id }}</h2>
            <div>
                <v-btn
                    fab
                    x-small
                    elevation="0"
                    :title="showSubtask ? 'Свернуть подзадание' : 'Развернуть подзадание'"
                    class="mr-3"
                    @click="toggleSubtask(!showSubtask)"
                >
                    <v-icon>mdi-chevron-{{showSubtask ? 'down' : 'up'}}</v-icon>
                </v-btn>
                <v-btn
                    fab
                    x-small
                    elevation="0"
                    title="Удалить подзадание"
                    @click="remove"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </div>
        </div>

        <div v-show="showSubtask">

            <div v-if="!hidePrintable" class="d-flex align-center">

                <v-switch v-model="showPrintVersion" label="Показать версию для печати" />
                
                <v-btn
                    v-if="showPrintVersion"
                    small
                    class="ml-5"
                    @click="copyHtmlToPrintableHtml"
                >Восстановить состояние из основной версии</v-btn>
            </div>

            <v-label v-if="!subtask.htmlPrintable">Интерактивная и печатная версии синхронизированы</v-label>
            <div v-else class="d-flex flex-column">
                <span class="red--text">Интерактивная и печатная версии разсинхронизированы.</span>
                <sub class="mb-4">В случае изменения верстки интерактивной версии требуется вручную поправить и печатную</sub>
                <span v-if="subtask.html === subtask.htmlPrintable">Интерактивная версия <b>идентична</b> к печатной</span>
                <span v-else>Интерактивная версия <b>отличается</b> от печатной</span>
            </div>
            

            <wysiwyg-editor
                v-show="!showPrintVersion"
                :value="makeImagesPathReadable(subtask.html)"
                use-shortcodes
                @input="onWysiwygInput"
            />

            <wysiwyg-editor
                v-show="showPrintVersion"
                :value="subtask.htmlPrintable"
                use-shortcodes
                @input="onWysiwygInput"
            />
            
            <template v-if="subtask.inputs.length">
                <div class="d-flex justify-space-between align-center mt-10 mb-5">
                    <h3 class="my-0">Настройки шорткодов</h3>

                    <v-chip
                        link
                        class="ml-1"
                        @click.native="switchSubtaskScoringType"
                    >{{ getScoringTypesText(subtask.scoringType) }}</v-chip>
                    
                    <v-spacer />
                    <v-btn
                        fab
                        x-small
                        elevation="0"
                        :title="showInputs ? 'Свернуть настройки' : 'Развернуть настройки'"
                        @click="toggleInputs(!showInputs)"
                    >
                        <v-icon>mdi-chevron-{{showInputs ? 'down' : 'up'}}</v-icon>
                    </v-btn>
                </div>

                <div v-if="showInputs">
                    <div class="input-wrapper d-flex flex-column">
                        <div
                            v-for="input in subtask.inputs"
                            :key="input.id"
                            class="input-item d-flex flex-column mb-5 py-2 px-5"
                        >
                            <div class="d-flex justify-space-between">
                                <div class="d-flex align-center">
                                    <div
                                        class="group-selector d-flex align-center justify-end mr-5"
                                        :style="{backgroundColor: !getInputGroupItem(input.id) ? null : groupColors[getInputGroupItem(input.id).value] }"
                                    >
                                        <span class="d-flex align-center justify-center mx-2">
                                            <template v-if="getInputGroupItem(input.id)">
                                                <v-icon
                                                    class="c-pointer mr-1"
                                                    @click.native="removeInputFromGroup(input.id)"
                                                >mdi-close</v-icon>
                                                <v-chip
                                                    link
                                                    class="mr-1"
                                                    @click.native="switchGroupScoringType(getInputGroupItem(input.id).value)"
                                                >{{ getScoringTypesText(getInputGroup(input.id).scoringType) }}</v-chip>
                                            </template>
                                            <v-select
                                                :value="getInputGroupItem(input.id)"
                                                :items="availableGroups"
                                                dense
                                                hide-details
                                                style="width: 115px"
                                                placeholder="Без группы"
                                                @change="onGroupSelected($event, input.id)"
                                                
                                            />
                                        </span>
                                    </div>
                                    <span class="mr-5">id: {{ input.id }}</span>
                                    <strong class="mr-5">{{ $const.editorBlockTypes[input.title] || input.title }}</strong>
                                    <v-text-field 
                                        label="Оценка" 
                                        :value="input.maxScore"
                                        type="number"
                                        style="width: 50px"
                                        class="mb-n2"
                                        @input="onScoreUpdate(input.id, $event)"
                                    ></v-text-field>
                                </div>
                                <v-btn
                                    fab
                                    x-small
                                    elevation="0"
                                    :title="!shortcodeIdsToHide.includes(input.id) ? 'Свернуть настройки шорткода' : 'Развернуть настройки шорткода'"
                                    @click="toggleInput(input.id)"
                                >
                                    <v-icon>mdi-chevron-{{!shortcodeIdsToHide.includes(input.id) ? 'down' : 'up'}}</v-icon>
                                </v-btn>
                            </div>

                            <div v-if="!shortcodeIdsToHide.includes(input.id)">
                                <component
                                    :is="input.type"
                                    :original-correct-answer="input.correctAnswer"
                                    :original-settings="input.settings"
                                    @update="onInputUpdate(input.id, $event)"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <v-divider />
                <p class="mb-0 mt-1">Максимальный балл за подзадание: <strong>{{ getMaxScore(subtask) }}</strong></p>
            </template>
        </div>
    </div>
</template>

<script>
import { computed } from 'vue'
import WysiwygEditor from '@/components/inputs/wysiwyg-editor/index.vue'
import taskInteractivesComponents from '@/plugins/taskInteractivesComponents.js'
import { JOB_SERVER } from '@/servers'

const scoringTypes = {
    scoresSum: 'scoresSum',
    generalScore: 'generalScore'
}
const scoringTypesText = {
    scoresSum: 'Сумма баллов',
    generalScore: 'Общий балл'
}

export default {
    props: {
        subtask: { type: Object, required: true },
        hideHeader: { type: Boolean, default: false },
        hidePrintable: { type: Boolean, default: false }
    },
    inject: ['model'],
    provide () {
        return {
            inputs: computed(() => this.subtask.inputs)
        }
    },
    components: { WysiwygEditor, ...taskInteractivesComponents },
    data () {
        return {
            showPrintVersion: false,
            showSubtask: true,
            showInputs: true,
            shortcodeIdsToHide: []
        }
    },
    computed: {
        groupColors () {
            return {
                1: '#feb5b5',
                2: '#b5bcfe',
                3: '#b5feb8',
                4: '#feecb5',
                5: '#b5f8fe'
            }
        },
        availableGroups () {
            const groups = this.subtask.groups.map(g => ({ text: 'Группа '+g.id, value: g.id }));
            const maxId = this.subtask.groups.reduce((prev, group) => group.id > prev ? group.id : prev, 0)
            const newGroup = {
                text: 'Новая группа',
                value: maxId + 1
            };
            return [ ...groups, newGroup ]
        }
    },
    mounted () {
        // Hiding all inputs after initialize
        this.subtask.inputs.forEach(input => this.toggleInput(input.id));
    },
    methods: {
        makeImagesPathReadable (html) {
            return html
                .replaceAll('src="/upload', `src="${JOB_SERVER}/upload`)
                .replaceAll('src="../../upload', `src="${JOB_SERVER}/upload`)
        },
        makeImagesPathRelative (html) {
            return html.replaceAll(`src="${JOB_SERVER}/upload`, 'src="../../upload')
        },
        // TODO: backspace must envoke input event
        onWysiwygInput (html) {
            if (typeof html !== 'string') throw new Error('Given argument should be a type of String.')

            // Auto-fix for images to save it with flexible path
            html = this.makeImagesPathRelative(html)

            this.showPrintVersion ?
                this.update({
                    ...this.subtask,
                    htmlPrintable: html
                }) :
                this.updateSubtask(html)
        },
        copyHtmlToPrintableHtml () {
            this.update({
                ...this.subtask,
                htmlPrintable: this.subtask.html
            })
        },
        // TODO: need to debounce it
        updateSubtask (html) {
            // Looking for shortcodes
            // TODO: Эта регулярка повторяется в двух местах. Возможно лучше вынести в единую точку
            // чтобы при изменениях в одном месте, в другом не потерялось
            const regex = /@@shortcode-(.*?)id="(\d*)".*?@@/gm;
            const foundShortcodes = [];
            const ids = []
            let foundIds = null;
            let match;

            while ((match = regex.exec(html)) !== null) {
                // This is necessary to avoid infinite loops with zero-width matches
                if (match.index === regex.lastIndex) { regex.lastIndex++ }
                const type = match[1].trim();
                const id = parseInt(match[2]);
                ids.push(id);
                foundShortcodes.push({id, type});
            }
            foundIds = foundShortcodes.map(el => el.id);
            // Remove unfound Ids from groups
            const subtaskGroups = _.cloneDeep(this.subtask.groups);
            subtaskGroups.forEach(group => {
                group.inputIds = group.inputIds.filter(id => foundIds.includes(id));
            });
            // Remove inputs with unfound Ids
            const subtaskInputs = this.subtask.inputs.filter(input => foundIds.includes(input.id));
            const newInputs = [];
            foundShortcodes.forEach((shortcode) => {
                // If not exist between inputs
                if (subtaskInputs.findIndex(input => input.id === shortcode.id) < 0) {
                    newInputs.push({
                        ...shortcode,
                        settings: {},
                        correctAnswer: null,
                        check: {},
                        userAnswer: '',
                        maxScore: 0,
                        title: this.getTitleByType(shortcode.type)
                    });
                }
            });
            let inputs = [...subtaskInputs, ...newInputs]
            // Выполняем сортировку инпутов по их порядку в разметке
            inputs = inputs.map((input, index) => inputs.find(input => input.id === ids[index]))
            this.update({
                ...this.subtask,
                groups: subtaskGroups,
                inputs,
                html
            });
        },
        getTitleByType (type) {
            return this.$const[type] || type;
        },
        remove () {
            this.$emit('remove', this.subtask);
        },
        toggleSubtask (value) {
            this.showSubtask = !!value;
        },
        toggleInputs (value) {
            this.showInputs = !!value;
        },
        toggleInput (id) {
            if (this.shortcodeIdsToHide.includes(id)) {
                this.shortcodeIdsToHide = this.shortcodeIdsToHide.filter(_id => _id !== id);
            } else {
                this.shortcodeIdsToHide.push(id);
            }
        },
        onScoreUpdate (inputId, value) {
            this.onInputUpdate(inputId, { maxScore: parseInt(value) });
        },
        onInputUpdate (id, payload) {
            const inputIndex = this.subtask.inputs.findIndex(input => input.id === id);
            if (inputIndex < 0) { return }
            
            let inputs = _.cloneDeep(this.subtask.inputs);
            
            Object.keys(payload).forEach((key) => {
                inputs[inputIndex][key] = payload[key];
            });
            this.update({...this.subtask, inputs});
        },
        update (value) {
            this.$emit('input', value);
        },
        getInputGroupItem (inputId) {
            if (!this.subtask.groups.length) { return false }
            const groupIndex = this.subtask.groups.findIndex(group => group.inputIds.findIndex(_id => _id === inputId) >= 0 );
            if (groupIndex < 0) { return false }
            const group = this.subtask.groups[groupIndex];
            return { text: 'Группа ' + group.id, value: group.id };
        },
        getInputGroup (inputId) {
            if (!this.subtask.groups.length) { return false }
            const groupIndex = this.subtask.groups.findIndex(group => group.inputIds.findIndex(_id => _id === inputId) >= 0 );
            if (groupIndex < 0) { return false }
            return this.subtask.groups[groupIndex];
        },
        onGroupSelected (groupId, inputId) {
            let groups = _.cloneDeep(this.subtask.groups);
            let selectedGroup = groups.find(group => group.id === groupId);
            if (!selectedGroup) {
                // Add new group
                const newGroup = {
                    id: groupId,
                    inputIds: [inputId],
                    scoringType: scoringTypes.scoresSum
                };
                groups.push(newGroup);
                selectedGroup = newGroup;
            }
            // Remove inputId from any groups
            groups.forEach((group) => {
                group.inputIds = group.inputIds.filter(id => id !== inputId);
            });
            // Add inputId to selected group
            selectedGroup.inputIds.push(inputId);
            this.update({ ...this.subtask, groups });
        },
        // Remove inputId from any groups
        removeInputFromGroup (inputId) {
            let groups = _.cloneDeep(this.subtask.groups);
            groups.forEach((group) => {
                group.inputIds = group.inputIds.filter(id => id !== inputId);
            });
            this.update({ ...this.subtask, groups });
        },
        switchSubtaskScoringType () {
            this.subtask.scoringType = this.subtask.scoringType === scoringTypes.scoresSum ?
                                        scoringTypes.generalScore :
                                        scoringTypes.scoresSum;
            this.update({ ...this.subtask });
        },
        switchGroupScoringType (groupId) {
            let groups = _.cloneDeep(this.subtask.groups);
            const selectedGroupIndex = this.subtask.groups.findIndex(g => g.id === groupId);
            if (selectedGroupIndex < 0) { return }
            const selectedGroup = groups[selectedGroupIndex];
            selectedGroup.scoringType = selectedGroup.scoringType === scoringTypes.scoresSum ? scoringTypes.generalScore : scoringTypes.scoresSum;
            this.update({ ...this.subtask, groups });
        },
        getScoringTypesText (type) {
            return scoringTypesText[type];
        },
        getMaxScore (subtask) {
            return subtask.inputs.map(input => input.maxScore).reduce((prev, cur) => prev + cur, 0);
        }
    }
}
</script>

<style lang="scss" scoped>
.input-item {
    border-radius: 12px;
    border: 1px solid #eee;
}
.group-selector {
    min-width: 270px;
    height: 50px;
    background-color: rgb(230, 230, 230);
    border-radius: 12px;
}
</style>
