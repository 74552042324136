<template>
    <div class="d-flex flex-column">
        <v-text-field
            label="Подпись" 
            v-model="settings.label"
        />
        <v-text-field
            label="Доступные расширения файла" 
            v-model="local.extentions"
            hint="Перечисляйте значения через запятую"
            @input="onExtentionsUpdated"
        />

        <div class="d-flex">
            <v-checkbox
                v-model="local.textInputMultilineCheckbox"
                label="Отображать многострочное поле ввода на печатной версии"
                @change="updateTextInputMultilineSetting"
            ></v-checkbox>
        </div>

        <div v-if="settings.textInputMultiline" class="d-flex align-center">
            <v-text-field
                v-model="settings.textInputMultiline.label"
                label="Подпись многострочного поля ввода"
                class="mr-4"
            />
            <v-text-field
                label="Кол-во строк многострочного поля ввода" 
                v-model="settings.textInputMultiline.rowsOnPrint"
                type="number"
                class="maxW-200"
            />
        </div>

        <wysiwyg-editor v-model="correctAnswer" label="Правильный ответ" />
    </div>
</template>

<script>
import interactiveMixin from '@/mixins/interactiveMixin.vue'
import WysiwygEditor from '@/components/inputs/wysiwyg-editor/index.vue'

export default {
    mixins: [interactiveMixin],
    components: { WysiwygEditor },
    data () {
        return {
            local: {
                extentions: '',
                textInputMultilineCheckbox: false
            },
            settings: {
                extentions: ['png', 'jpg', 'jpeg', 'bmp', 'tiff'],
                label: 'Прикрепить решение',
                textInputMultiline: null
            }
        }
    },
    created () {
        this.local.extentions = this.settings.extentions.join(',')
        this.local.textInputMultilineCheckbox = !!this.settings.textInputMultiline
    },
    methods: {
        onExtentionsUpdated (value) {
            this.settings.extentions = value.split(',').map(str => str.trim()).filter(str => !!str)
        },
        updateTextInputMultilineSetting (value) {
            if (value)
                this.settings.textInputMultiline = { label: '', rowsOnPrint: 4 }
            else
                this.settings.textInputMultiline = null
        }
    }
}
</script>