<template>
    <div>
        <p>Логические спец-символы:</p>
        <ul>
            <li><code>¬</code> - Инверсия (НЕ)</li>
            <li><code>/\</code> - Умножение (И)</li>
            <li><code>\/</code> - Сложение (ИЛИ)</li>
        </ul>
        <br>
        <v-text-field 
            label="Исходное логическое выражение" 
            v-model="settings.expression"
        />

        <div class="d-flex align-center">
            <v-text-field 
                label="Отступ поля ввода заголовка от краев ячейки"
                v-model="settings.gap"
                type="number"
                class="maxW-250 mr-5"
            />

        </div>

        <div class="d-flex align-center">
            <v-checkbox
                label="Флаг заполнения букв в заголовке" 
                v-model="settings.prefilledHeaders"
                type="checkbox"
                class="mr-5"
            />

            <v-checkbox
                label="Флаг заполнения начальных значений букв в левой части таблицы" 
                v-model="settings.prefilledInitialValues"
                type="checkbox"
            />
        </div>
    </div>
</template>

<script>
import interactiveMixin from '@/mixins/interactiveMixin.vue'

export default {
    mixins: [interactiveMixin],
    data () {
        return {
            correctAnswer: {},
            settings: {
                expression: "", //(¬A \\/ B /\\ ¬ C) /\ C// Исходное логическое выражение
                gap: 10, // Отступ поля ввода зоголовка от краев ячейки
                prefilledHeaders: true, // Флаг заполнения букв в заголовке => кодом не обрабатывается
                prefilledInitialValues: true // Флаг заполнения начальных значений букв в левой части таблицы
            }
        }
    },
    // created () {
    //     this.expression = this.settings.expression.replaceAll('\\\\', '\\')
    // },
    // methods: {
    //     afterExpressionChange () {
    //         this.settings.expression = this.expression.replaceAll('\\', '\\\\')
    //     }
    // }
}
</script>