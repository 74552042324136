<template>
    <form-view 
        v-slot="{ model, onSuccess, onBack }"
        title="Редактирование задания"
        update
        :url-update="updateUrl"
        :url-back="backUrl"
        action-one="task/one"
        :fields="fields"
    >
        <task-form :model="model" @success="onSuccess" @back="onBack" />
    </form-view>
</template>
<script>
import FormView from '@/components/crud/FormView.vue'
import TaskForm from '@/components/forms/TaskForm.vue'
export default {
    name: 'TaskUpdate',
    components: { FormView, TaskForm },
    computed: {
        fields () {
            return [ 'year', 'subject', 'grade', 'option', 'task', 'themes', 'data', 'category', 'collection' ]
        },
        backUrl () {
            return `/task${window.location.search}`
        },
        updateUrl () {
            return `/task/update/:id${window.location.search}`
        }
    }
}
</script>