<template>
    <div class="form-view">
        <v-row align="start" justify="start" dark>
            <v-col cols="12" class="d-flex flex-start">
                <div class="text-h6" v-html="title"></div>
            </v-col>
        </v-row>
       
        <v-row align="center" justify="center" dark>
            <v-col cols="12" sm="12" md="12">
                <v-progress-circular
                  v-if="waiting"
                  :size="50"
                  :color="$const.color.primary"
                  indeterminate
                ></v-progress-circular>

                <div v-else>
                    <v-alert
                      v-if="!_.isNil(error)"
                      dense
                      type="error"
                    >
                        {{ error }}
                    </v-alert>

                    <slot :model="model" :onSuccess="onSuccess" :onBack="onBack" />
                </div>
            </v-col>
        </v-row>

        <v-snackbar v-model="snackbar.success" :timeout="3000">
            Изменения успешно сохранены!
            <template v-slot:action="{ attrs }">
                <v-btn dark text v-bind="attrs" @click="snackbar.success = false">
                    Продолжить
                </v-btn>

                <v-btn :color="$const.color.primary" dark v-bind="attrs" :to="urlBack">
                    Вернуться к списку
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>
<script>
export default {
    name: 'FormView',
    props: {
        title: String,
        create: Boolean,
        update: Boolean,
        urlUpdate: String,
        urlBack: String,
        actionOne: String,
        fields: { type: Array, default: () => ['id'] }
    },
    data() {
        return {
            model: null,
            waiting: false,
            error: null,
            snackbar: {
                success: false
            }
        }
    },
    methods: {
        onBack() {
            return this.$router.push(this.urlBack).catch(() => {});
        },
        onSuccess(data) {
            const goBack = _.get(data, 'goBack', false);

            if(goBack === true)
                return this.$router.push(this.urlBack).catch(() => {});
            else
            {
                if(this.create)
                {
                    const urlUpdate = _.replace(this.urlUpdate, ':id', data.id);
                    return this.$router.push(urlUpdate).catch(() => {});
                }
                else if(this.update)
                {
                    this.model = data;
                    this.snackbar.success = true;
                }
            }

            return ;
        }
    },
    async created(){
        const id = _.get(this, '$route.params.id', 0);
        const baseId = _.get(this, '$route.query.base_id', null)

        if (this.update || (this.create && !_.isNil(baseId)))
        {
            this.waiting = true;
            if(this.update && id <= 0)
            {
                this.error = 'Неверный запрос.';
            }
            else
            {
                const { success, error, data } = await this.$store.dispatch(this.actionOne, {
                    id: this.update ? id : baseId,
                    fields: this.fields.join(',')
                });

                if(success)
                    this.model = this.update ? data : _.omit(data, ['id', 'created_at', 'updated_at', 'created_by', 'updated_by']);
                else
                    this.error = error;

                this.waiting = false;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.form-view {
    max-width: 1000px;
    margin: auto;
}
</style>