<template>
  <div>
        <div v-if="model" class="mb-5">
            <v-label>Фоновая картинка активной области</v-label>
            <div class="d-flex align-center mt-2">
                <media-library
                    v-model="settings.image.src"
                    :path="`${model.year}/${transliterate(model.subject.toLowerCase())}/${model.grade}`"
                    label="Изображение"
                />
                <v-divider v-if="settings.image.src" vertical class="mx-5" />
                <v-dialog
                    v-if="settings.image.src"
                    v-model="dialog"
                    fullscreen
                    hide-overlay
                    transition="dialog-bottom-transition"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            v-bind="attrs"
                            v-on="on"
                        >Разметить изображение</v-btn>
                    </template>
                    <polygon-drawer
                        v-model="dialog"
                        :polygons="correctAnswer"
                        :image="settings.image.src"
                        :input-type="settings.textInputSettings.isDigital ? 'number' : 'text'"
                        @save="onPolygonsSave"
                    />
                </v-dialog>
            </div>
        </div>
        <v-alert v-else type="warning" class="my-2">
            Возможность использования медиа-библиотеки заблокирована для несохранённой формы.
            Сохраните форму и обновите страницу.
        </v-alert>

        <v-label>Настройки полей ввода</v-label>
        <div class="d-flex">
            <v-checkbox
                v-model="settings.textInputSettings.isDigital"
                label="Цифровое поле"
                class="mt-0"
            ></v-checkbox>
            <v-checkbox
                v-model="settings.textInputSettings.considerDot"
                label="Учитывать точку"
                hint="Включить, когда точка и запятая имеют разные значения"
                class="mt-0 ml-4"
            ></v-checkbox>
            <v-checkbox
                v-model="settings.textInputSettings.considerMinus"
                label="Учитывать минус"
                class="mt-0 ml-4"
            ></v-checkbox>
            <v-checkbox
                v-model="settings.textInputSettings.considerCase"
                label="Учитывать регистр"
                class="mt-0 ml-4"
            ></v-checkbox>
        </div>
        <div class="d-flex mb-5">
            <v-text-field
                v-model="inputWidth"
                type="text"
                label="Ширина"
                class="maxW-150 pt-0 mt-0"
                @input="beforeUpdate"
            ></v-text-field>
            <v-text-field
                v-model="settings.textInputSettings.placeholder"
                type="text"
                label="Заполнитель (placeholder)"
                class="maxW-150 pt-0 mt-0 ml-4"
            ></v-text-field>
            <v-text-field
                v-model="settings.textInputSettings.maxLength"
                type="number"
                label="Макс. кол-во сим."
                hint="Если не указана, длина ввода не ограничена" 
                persistent-hint
                class="maxW-150 pt-0 mt-0 ml-4"
            ></v-text-field>
        </div>

        <v-label>Настройки черточек</v-label>
        <div class="d-flex">
            <v-checkbox
                v-model="settings.isDash"
                label="Отображать точку как черточку"
                class="mt-0 mr-4"
            ></v-checkbox>
            <v-text-field
                v-model="settings.dashHeight"
                type="number"
                label="Высота черточки (px)"
                :disabled="!settings.isDash"
                class="maxW-150 mr-4"
            ></v-text-field>
            <v-text-field
                v-model="settings.dashWidth"
                type="number"
                label="Ширина черточки (px)"
                :disabled="!settings.isDash"
                class="maxW-150"
            ></v-text-field>
        </div>

        <v-label>Настройки стрелок</v-label>
        <div class="d-flex">
            <v-text-field
                v-model="settings.arrow.width"
                type="number"
                label="Ширина (px)"
                class="maxW-150 mr-5"
            ></v-text-field>
            <div>
                <div class="d-flex flex-column align-start">
                    <v-label><sub>Цвет (Отобразить палитру)</sub></v-label>
                    <v-switch
                        v-model="showColorPicker"
                        class="maxW-150"
                    />
                </div>
                <v-color-picker
                    v-if="showColorPicker"
                    :value="settings.arrow.color"
                    class="maxW-150"
                    @input="onColorPickerInput"
                />
            </div>
        </div>
  </div>
</template>

<script>
import interactiveMixin from '@/mixins/interactiveMixin.vue'
import MediaLibrary from '@/components/inputs/media-library'
import PolygonDrawer from './PolygonDrawer.vue'
import StringHelper from '@/plugins/string'

const defaultArrowColor = 'rgb(0, 0, 255)'

export default {
    mixins: [interactiveMixin],
    components: { MediaLibrary, PolygonDrawer },
    inject: ['model'],
    data () {
        return {
            correctAnswer: [],
            showColorPicker: false,
            dialog: false,
            inputWidth: '',
            settings: {
                arrow: {
                    color: defaultArrowColor,
                    width: '2', // px
                },
                image: { src: null },
                isDash: false, //вертикальная черта вместо точки клика
                dashHeight: 20, //высота вертикальной черточки
                dashWidth: 4, //ширина вертикальной черточки
                textInputSettings: { 
                    placeholder: '',
                    comparisonType: 'direct',
                    considerCase: false,
                    considerDot: false,
                    considerMinus: false,
                    isDigital: false,
                    considerWhiteSpace: false
                }
            }
        }
    },
    computed: {
        transliterate () { return StringHelper.transliterate }
    },
    mounted () {
        // Broadcast initial values to local variables
        if (this.originalCorrectAnswer) {
            this.correctAnswer = this.originalCorrectAnswer
        }
        if (this.originalSettings) {
            Object.keys(this.originalSettings).forEach((key) => {
                this.settings[key] = this.originalSettings[key]
            })
            this.settings.arrow.color = this.transformColorToString(this.settings.arrow.color)
        }
        if (this.settings.textInputSettings.width) {
            if (this.settings.textInputSettings.width.includes?.('%')) {
                this.inputWidth = this.settings.textInputSettings.width
            } else if (parseInt(this.settings.textInputSettings.width)) {
                this.inputWidth = parseInt(this.settings.textInputSettings.width)
            }
        }
    },
    methods: {
        onPolygonsSave (polygons) {
            this.correctAnswer = polygons
        },
        onColorPickerInput (event) {
            this.settings.arrow.color = this.transformColorToString(event)
        },
        transformColorToString (value) {
            let color = defaultArrowColor
            // Если в переменной объект, то преобразовываем в rgba строку
            if (typeof value === 'object') {
                const rgba = value.rgba
                color = `rgba(${rgba.r}, ${rgba.g}, ${rgba.b}, ${rgba.a})`
            }
            // Если в переменной строка, то это подходящий вариант и мы присваиваем
            // это значение в итоговую переменную
            else if (typeof value === 'string') {
                color = value
            }
            // Иначе считаем что в value указано любое другое значение,
            // а значит мы оставляем значение defaultArrowColor
            return color
        },
        beforeUpdate () {
            const color = this.transformColorToString(this.settings.arrow.color)
            if (this.inputWidth) {
                if (this.inputWidth?.includes?.('%')) {
                    this.settings.textInputSettings.width = this.inputWidth
                } else {
                    this.settings.textInputSettings.width = `${parseInt(this.inputWidth) || 0}px`
                }
            } else {
                this.settings.textInputSettings.width = null
            }
            return {
                correctAnswer: this.correctAnswer,
                settings: {
                    ...this.settings,
                    arrow: {
                        ...this.settings.arrow,
                        color
                    }
                }
            }
        }
    }
}
</script>