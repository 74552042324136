<template>
    <div>
        <wysiwyg-editor v-model="correctAnswer[0]" label="Правильный ответ" class="mb-4"/>

        <v-label>Исходный текст</v-label>
        <br>
        <v-label><span style="font-size: 14px;">Используйте символ <code style="font-size: 16px;">…</code>, в качестве пропущенных символов. Обратите внимание, чтот символ не комбинация трех точек, а отдельный спец-символ.</span></v-label>
        <wysiwyg-editor v-model="settings.text" />
    </div>
</template>

<script>
import interactiveMixin from '@/mixins/interactiveMixin.vue'
import WysiwygEditor from '@/components/inputs/wysiwyg-editor/index.vue'

export default {
    mixins: [ interactiveMixin ],
    components: { WysiwygEditor },
    inject: ['model'],
    data () {
        return {
            correctAnswer: [''],
            settings: {
                text: ''
            }
        }
    },
    created () {
        if (typeof this.correctAnswer === 'string')
            this.correctAnswer = [this.correctAnswer]
    }
}
</script>