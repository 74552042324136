<template>
  <div>
        <v-label>Настройки холста</v-label>
        <div class="d-flex">
            <v-text-field
                v-model="settings.pointsOffset"
                type="number"
                label="Растояние между точками (px)"
                class="maxW-150 mr-5"
                @input="onMeasuresSettingsChange"
            />

            <v-text-field
                v-model="settings.pointsPerWidth"
                type="number"
                label="Точек по горизонтали"
                class="maxW-100 mr-5"
                @input="onMeasuresSettingsChange"
            />

            <v-text-field
                v-model="settings.pointsPerHeight"
                type="number"
                label="Точек по вертикали"
                class="maxW-100 mr-5"
                @input="onMeasuresSettingsChange"
            />

            <v-select
                v-model="settings.comparisonType"
                :items="comparisonTypes"
                label="Тип проверки"
                class="maxW-200 mr-5"
            />

            <v-text-field
                v-if="settings.comparisonType === 'rrx'"
                v-model="settings.rrXValue"
                type="number"
                label="Площадь прямоугольника (см2)"
                class="maxW-200"
            />
        </div>
        
        <p v-if="settings.comparisonType">
            Укажите возможный вариант правильного ответа.<br>Нарисованная вами фигура будет показана по кнопке "Посмотреть ответ".
        </p>
        <p v-else>
            Укажите все варианты правильного ответа.
        </p>

        <v-dialog
            v-model="dialog"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    v-bind="attrs"
                    v-on="on"
                >Разметить задание</v-btn>
            </template>
            <grid-setup-editor
                :key="`${settings.pointsOffset}_${settings.pointsPerWidth}_${settings.pointsPerHeight}`"
                v-model="dialog"
                :points.sync="correctAnswer"
                :presets.sync="settings.preset"
                :points-offset="parseInt(settings.pointsOffset)"
                :points-per-width="parseInt(settings.pointsPerWidth)"
                :points-per-height="parseInt(settings.pointsPerHeight)"
            />
        </v-dialog>
  </div>
</template>

<script>
import interactiveMixin from '@/mixins/interactiveMixin.vue'
import GridSetupEditor from '@/components/task/editor/common/GridSetupEditor/Index.vue'

const defaultPreset = () => ({ lines: [], points: [], marks: [] })

export default {
    mixins: [interactiveMixin],
    components: { GridSetupEditor },
    inject: ['model'],
    data () {
        return {
            correctAnswer: [],
            dialog: false,
            settings: {
                pointsOffset: 20, // px
                pointsPerWidth: 10,
                pointsPerHeight: 10,
                comparisonType: null,
                rrXValue: 0,
                preset: defaultPreset()
            }
        }
    },
    computed: {
        comparisonTypes () {
            return [
                { value: null, text: 'Прямое сравнение' },
                { value: 'tt', text: '2 равных треугольника' },
                { value: 'rr', text: '2 равных прямоугольника' },
                { value: 'ff', text: '2 равные фигуры' },
                { value: 'ss', text: '2 квадрата' },
                { value: 'rr1', text: '2 неравных прямоугольника' },
                { value: 'sr', text: 'Квадрат и прямоугольник' },
                { value: 'rrx', text: 'Два прямоугольника, площадь одного из которых Х см2' },
                { value: 't5', text: 'Треугольник и пятиугольник' },
                { value: 't4', text: 'Треугольник и четырехугольник' },
            ]
        }
    },
    methods: {
        beforeUpdate () {
            ['pointsOffset', 'pointsPerWidth', 'pointsPerHeight', 'rrXValue'].forEach((key) => {
                this.settings[key] = parseInt(this.settings[key])
            })
            return {
                correctAnswer: this.correctAnswer,
                settings: this.settings
            }
        },
        onMeasuresSettingsChange () {
            // Обнуляем пресет и ответы, потому настройки позиций придется пересчитывать
            this.settings.preset = defaultPreset()
            this.correctAnswer = []
        }
    }
}
</script>