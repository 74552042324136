<template>
    <div>
        
        <br />
        <v-expansion-panels>
            <v-expansion-panel>
                <v-expansion-panel-header>Этап инструкций</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <div class="d-flex flex-column">
                        <div class="d-flex align-center">
                            <v-text-field
                                v-model="settings.instruction.buttonText"
                                label="Текст кнопки"
                                class="mr-2"
                            ></v-text-field>
                        </div>
                        <v-label>Вёрстка инструкции</v-label>
                        <wysiwyg-editor v-model="settings.instruction.html" />
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
                <v-expansion-panel-header>Этап подготовки</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <div class="d-flex flex-column">
                        <v-text-field
                            v-model="settings.preparation.timeLimit"
                            label="Ограничение этапа по времени (сек)"
                            type="number"
                            class="maxW-250"
                        ></v-text-field>
                        <v-label>Вёрстка этапа подготовки</v-label>
                        <wysiwyg-editor v-model="settings.preparation.html" />
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
                <v-expansion-panel-header>Интерактив</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <div class="d-flex flex-column">

                        <div class="d-flex align-center">
                            <v-text-field
                                v-model="settings.interactive.timeLimit"
                                label="Ограничение этапа по времени (сек)"
                                type="number"
                                class="maxW-250"
                            ></v-text-field>
                        </div>
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>

        </v-expansion-panels>
    </div>
</template>

<script>
import interactiveMixin from '@/mixins/interactiveMixin.vue'
import WysiwygEditor from '@/components/inputs/wysiwyg-editor/index.vue'

export default {
    mixins: [ interactiveMixin ],
    components: { WysiwygEditor },
    data () {
        return {
            correctAnswer: {},
            settings: {
                instruction: {
                    html: '',
                    buttonText: "Начать задание"
                },
                preparation: {
                    html: '',
                    timeLimit: 15
                },
                interactive: {
                    timeLimit: 180
                }
            }
        }
    }
}
</script>