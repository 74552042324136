<template>
    <div class="w-100 d-flex align-center">
        <template
            v-for="toolsCategory in availableToolsCategories"
        >
            <span :key="`${toolsCategory.name}_title`" class="mr-2">{{ toolsCategory.title }}:</span>
            <v-btn
                v-for="tool in availableTools.filter((tool) => tool.category === toolsCategory.name)"
                :key="`${toolsCategory}_${tool.name}`"
                :title="tool.title"
                icon
                outlined
                class="tool-btn mr-2"
                :class="{ active: activeTool === tool.name }"
                @click="switchTool(tool.name)"
            >
                <v-icon>{{ tool.icon }}</v-icon>
            </v-btn>
            <v-divider :key="`${toolsCategory.name}_divider`" vertical class="mx-5" />
        </template>
        
        <v-btn outlined @click="addPointVariant">
            + Вариант ответа
        </v-btn>
    </div>
</template>

<script>
export default {
    model: {
        prop: 'activeTool',
        event: 'change'
    },
    props: {
        acceptedTools: { type: Array, default: () => [] },
        activeTool: { type: String, default: null }
    },
    data () {
        return {
            tools: [
                {
                    title: 'Линия',
                    name: 'answerLine',
                    icon: 'mdi-chart-line-variant',
                    category: 'answer'
                },
                {
                    title: 'Точка',
                    name: 'presetPoint',
                    icon: 'mdi-vector-point',
                    category: 'conditions'
                },
                {
                    title: 'Линия',
                    name: 'presetLine',
                    icon: 'mdi-vector-line',
                    category: 'conditions'
                },
                {
                    title: 'Метка',
                    name: 'presetMark',
                    icon: 'mdi-target-variant',
                    category: 'conditions'
                },
                {
                    title: 'Ластик',
                    name: 'generalEraser',
                    icon: 'mdi-eraser',
                    category: 'general'
                }
            ],
            toolsCategories: [
                {
                    title: 'Ответ',
                    name: 'answer'
                },
                {
                    title: 'Условие',
                    name: 'conditions'
                },
                {
                    title: 'Общее',
                    name: 'general'
                }
            ]
        }
    },
    computed: {
        availableTools () {
            if (!this.acceptedTools.length) return this.tools

            return this.tools.filter(t => this.acceptedTools.includes(t.name))
        },
        availableToolsCategories () {
            const usedCategories = Array.from(new Set(this.availableTools.map(t => t.category)))
            return this.toolsCategories.filter(cat => usedCategories.includes(cat.name))
        }
    },
    methods: {
        /**
         * Метод переключает значение активного инструмента.
         * Если передано тоже самое значение или `null`,
         * то отключаем активность инструмента
         * @param {String} toolName Название инструмента
         */
        switchTool (toolName) {
            const newValue = this.activeTool === toolName ? null : toolName
            this.$emit('change', newValue)
        },
        addPointVariant () {
            this.$emit('btnClick:addPointVariant')
        }
    }
}
</script>

<style lang="scss" scoped>
.tool-btn {
    &.active {
        background: grey;
        color: white;
    }
}
</style>