<template>
    <div>
        
        <br />
        <v-expansion-panels>
            <v-expansion-panel>
                <v-expansion-panel-header>Этап инструкций</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <div class="d-flex flex-column">
                        <div class="d-flex align-center">
                            <v-text-field
                                v-model="settings.instruction.buttonText"
                                label="Текст кнопки"
                                class="mr-2"
                            ></v-text-field>
                            <v-text-field
                                v-model="settings.instruction.comment"
                                label="Комментарий"
                                class="ml-2"
                            ></v-text-field>
                        </div>
                        <v-label>Вёрстка инструкции</v-label>
                        <wysiwyg-editor v-model="settings.instruction.html" />
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
                <v-expansion-panel-header>Этап подготовки</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <div class="d-flex flex-column">
                        <v-text-field
                            v-model="settings.preparation.timeLimit"
                            label="Ограничение этапа по времени (сек)"
                            type="number"
                            class="maxW-250"
                        ></v-text-field>
                        <v-label>Вёрстка этапа подготовки</v-label>
                        <wysiwyg-editor v-model="settings.preparation.html" />
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
                <v-expansion-panel-header>Аудио файл</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <div class="d-flex flex-column">
                        <v-text-field
                            v-model="settings.audio.comment"
                            label="Комментарий"
                        ></v-text-field>

                        <media-library
                            v-if="model"
                            v-model="settings.audio.src"
                            :path="`${model.year}/${transliterate(model.subject.toLowerCase())}/${model.grade}`"
                            label="Аудио файл"
                            type="mp3"
                            class="my-4"
                        />
                        <v-alert v-else type="warning" class="my-2">
                            Возможность использования медиа-библиотеки заблокирована для несохранённой формы.
                            Сохраните форму и обновите страницу.
                        </v-alert>
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
                <v-expansion-panel-header>Интерактив</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <div class="d-flex flex-column">

                        <div class="d-flex align-center">
                            <v-text-field
                                v-model="settings.interactive.timeLimit"
                                label="Ограничение этапа по времени (сек)"
                                type="number"
                                class="maxW-250 mr-2"
                            ></v-text-field>

                            <v-text-field
                                v-model="settings.interactive.comment"
                                label="Комментарий"
                                class="ml-2"
                            ></v-text-field>
                        </div>
                        <v-label>Вёрстка интерактивной части</v-label>
                        <subtask
                            :subtask="subtask"
                            hide-header
                            hide-printable
                            @input="onSubtaskUpdate"
                        />
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
                <v-expansion-panel-header>Этап самопроверки</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <div class="d-flex flex-column">

                        <div class="d-flex align-center">
                            <v-text-field
                                v-model="settings.check.timeLimit"
                                label="Ограничение этапа по времени (сек)"
                                type="number"
                                class="maxW-250 mr-2"
                            ></v-text-field>

                            <v-text-field
                                v-model="settings.check.comment"
                                label="Комментарий"
                                class="ml-2"
                            ></v-text-field>
                        </div>
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
                <v-expansion-panel-header>Финальный этап</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <div class="d-flex flex-column">

                        <div class="d-flex align-center">
                            <v-text-field
                                v-model="settings.complete.comment"
                                label="Комментарий"
                                class="ml-2"
                            ></v-text-field>
                        </div>
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>

        </v-expansion-panels>
    </div>
</template>

<script>
import interactiveMixin from '@/mixins/interactiveMixin.vue'
import WysiwygEditor from '@/components/inputs/wysiwyg-editor/index.vue'
import StringHelper from '@/plugins/string'
import MediaLibrary from '@/components/inputs/media-library'

export default {
    mixins: [ interactiveMixin ],
    components: {
        WysiwygEditor, MediaLibrary,
        // Import component below by lambda function is important to avoid loop dependecy
        Subtask: () => import('@/components/task/editor/Subtask.vue')
    },
    inject: ['model'],
    data () {
        return {
            correctAnswer: [],
            subtask: {},
            settings: {
                instruction: {
                    html: ``,
                    buttonText: "Начать задание",
                    comment: "",
                },
                preparation: {
                    html: ``,
                    timeLimit: 15,
                },
                audio: {
                    src: "",
                    comment: "",
                },
                interactive: {
                    html: ``,
                    inputs: [],
                    timeLimit: 30,
                    comment: "",
                },
                check: {
                    timeLimit: 15,
                    comment: "",
                },
                complete: {
                    comment: ""
                }
            }
        }
    },
    computed: {
        transliterate () { return StringHelper.transliterate }
    },
    created () {
        this.subtask = {...this.$const.defaultSubtaskObject}
    },
    methods: {
        onSubtaskUpdate (payload) {
            this.subtask = payload
            this.settings.interactive.html = payload.html
            this.settings.interactive.inputs = payload.inputs
        }
    }
}
</script>